import React from 'react'
import "../../Style/Who.scss"
import { Modal, Button } from 'react-bootstrap'
import { useState } from 'react';
import image7 from "../../Assets/Images/Who/image-7(1).png"
import image8 from "../../Assets/Images/Who/Image-8.png"
import { useEffect } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import cld from '../../config/cloudcf';
import { AdvancedImage, AdvancedVideo } from '@cloudinary/react';
function Who() {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const [show7, setShow7] = useState(false);
  const [show9, setShow9] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)

    gsap.from(".Line", {
      width: 0
    })
    gsap.to(".Line", {
      scrollTrigger: {
        trigger: ".Line"
      },
      width: "100%",
      duration: 2
    })
  })
  const Video = cld.video("rbn/who/RBN_vid")
  const image1 = cld.image("rbn/who/Image-1")
  const image2 = cld.image("rbn/who/Image-2")
  const image3 = cld.image("rbn/who/Image-5")
  const image4 = cld.image("rbn/who/Image-3")
  const image5 = cld.image("rbn/who/Image-4")
  const image6 = cld.image("rbn/who/Image-6")
  const image9 = cld.image("rbn/who/Image-7")
  return (
    <div className='Who-Container'>
      <div className='Section-1'>
        <div className='Title-Section'>
          <h1>Who We Are</h1>
          <div className='Line-Box'>
            <span className='Line' />
          </div>
        </div>
        <div className='Image-Section'>
          <AdvancedVideo cldVid={Video} controls={false} autoPlay={true} loop muted playsInline={true} />
        </div>
      </div>

      <div className='Section-2' id='Director'>
        <span className='Line-wht' />
        <h2>Board of Directors</h2>
        <div className='Content flex-wrap space-between'>
          <div className='Card'>
            <AdvancedImage cldImg={image1} />
            <h3>President Director
              & CEO
            </h3>
            <Button className='Buttons' onClick={handleShow}>+</Button>
          </div>
          <div className='Card'>
            <AdvancedImage cldImg={image2} />
            <h3>Vice President Director
            </h3>
            <Button className='Buttons' onClick={handleShow1}>+</Button>
          </div>
          <div className='Card'>
            <AdvancedImage cldImg={image9} />
            <h3>Chief Finance Officer
            </h3>
            <Button className='Buttons' onClick={handleShow9}>+</Button>
          </div>
          
          <div className='Card'>
            <img src={image7} />
            <h3>Chief Operating Officer
            </h3>
            <Button className='Buttons' onClick={handleShow6}>+</Button>
          </div>
          {/* <div className='Card'>
            <img src={image8} />
            <h3>Senior Vice
              President
              of Finance
            </h3>
            <Button className='Buttons' onClick={handleShow7}>+</Button>
          </div> */}
        </div>
        {/* <--- Commisioners ---> */}
        <span className='Line-wht' />
        <h2 id='Comm'>Board of Commissioners</h2>
        <div className='Content flex-wrap space-between'>
          <div className='Card'>
            <AdvancedImage cldImg={image6} />
            <h3>Commissioners
            </h3>
            <Button className='Buttons' onClick={handleShow4}>+</Button>
          </div>
        </div>
        <span className='Line-wht btm-20' />

      </div>
      {/* -- modal start -- */}
      <Modal id="who" centered show={show} onHide={handleClose}>
        <Modal.Body >
          <h3>President Director & Chief Executive Officer</h3>
          <h2>Stefan Mahir</h2>
          <p>A versatile professional specializing in various industries, mainly Real Estate, Manufacturing, Project Management, Agriculture, and Technology. Involved and led numerous enterprises with work experiences in different business environments; USA and South East Asia. Strong educational background with a demonstrated track record of success from Pepperdine University (International Business and Relations) and University of California Los Angeles “UCLA” (Project Management).</p>
        </Modal.Body>
      </Modal>

      <Modal id="who" centered show={show1} onHide={handleClose1}>
        <Modal.Body>
          <h3>Vice President Director</h3>
          <h2>Abdulbar Mansoer</h2>
          <p>Mr. Abdulbar Mansoer has strong credibility as the former President Director of ITDC (Indonesia Tourism Development Corporation). He was responsible for the planning and development of The Mandalika Lombok destination, with the landmark completion of the Mandalika International Circuit, the world class attraction which successfully hosted the World Superbike Race in 2021 and MotoGP in 2022. The company also built and operates The Nusa Dua Bali, the leading MICE resort in Indonesia which had hosted the G20 Meetings in 2022.</p>
        </Modal.Body>
      </Modal>

      <Modal id="who" centered show={show2} onHide={handleClose2}>
        <Modal.Body>
          <h3>Main Commissioner</h3>
          <h2>Bambang Brodjonegoro</h2>
          <p>Mr. Brodjonegoro was the Minister of Research and Technology/National Research and Innovation Agency of the Republic of Indonesia. Previously, he was the Minister of National Development Planning of Indonesia, taking office after a cabinet reshuffle by President Joko Widodo. From 2014 to 2016, he was the Finance Minister of Indonesia in the Working Cabinet also under President Joko Widodo's administration.</p>
        </Modal.Body>
      </Modal>

      <Modal id="who" centered show={show3} onHide={handleClose3}>
        <Modal.Body>
          <h3>Commissioner</h3>
          <h2>Marzuki Darusman</h2>
          <p>Mr. Darusman is an Indonesian lawyer and human rights campaigner. After fifteen years as a member of the People's Representative Council with President Suharto's Golkar party, he served as the country's prosecutor general from 1999 to 2001. Darusman has served on several national and international human rights commissions, and in August 2010, he became founding director of the Human Rights Resource Centre for ASEAN.</p>
        </Modal.Body>
      </Modal>

      <Modal id="who" centered show={show4} onHide={handleClose4}>
        <Modal.Body>
          <h3>Independent Commissioner  </h3>
          <h2>Darwin Cyril Noerhadi</h2>
          <p>Mr. Darwin Cyril has held various key leadership roles, including President Commissioner (Independent) at PT Mandiri Sekuritas and Chief Finance Officer at PT Medco Energi Internasional Tbk. He also served as a Corporate Finance Partner at PricewaterhouseCoopers (PwC) Indonesia and held positions as President Director of PT Bursa Efek Jakarta (BEJ), PT Kliring Deposit Efek Indonesia (KDEI), and Executive Director at PT Danareksa. Beyond executive roles, he was a Senior Managing Director at private equity firm Creador in Jakarta and served on the Supervisory Board of the Indonesia Investment Authority (INA), contributing to foreign investment initiatives under the guidance of Sri Mulyani and Erick Thohir.</p>
        </Modal.Body>
      </Modal>

      <Modal id="who" centered show={show5} onHide={handleClose5}>
        <Modal.Body>
          <h3>Independent Commissioner  </h3>
          <h2>Subarjo Joyosumarto, S.E, MA, PhD</h2>
          <p>Mr. Subarjo is a renowned figure in economy aspect. He had been contributing as a Director of Lembaga Pengembangan Perbankan Indonesia (LPPI) for 6 years until 2013. Prior to that, he successfully lead as CEO at International Center of Development in Islamic Finance (ICDIF) in 2013. He is a well respected figure in his field of work with numerous experince both nationally and internationally.</p>
        </Modal.Body>
      </Modal>
      <Modal id="who" centered show={show6} onHide={handleClose6}>
        <Modal.Body>
          <h3>Chief Operating Officer</h3>
          <h2>Bram Subiandoro</h2>
          <p>Mr. Bram possesses significant expertise in the construction and engineering sectors for 30 years. In his role as COO at VAST Bali, he led operations for an international project management consultancy. During his tenure as Managing Director at ITDC, he overlook national priority tourism destinations and infrastructure projects, such as Pertamina Mandalika International Street Circuit, Mandalika Urban Tourism and Infrastructure, and Shangri-La Bali Resort. Given his specialty, Mr. Bram are a crucial part to the company.</p>
        </Modal.Body>
      </Modal>
      <Modal id="who" centered show={show9} onHide={handleClose9}>
        <Modal.Body>
          <h3>Chief Finance Officer</h3>
          <h2>Muhamad Sageri</h2>
          <p>Commencing his professional journey in 2006, Mr. Sageri possesses an extensive experience and roles across diverse banking institutions within Indonesia's financial landscapes. His most recent engagements as Head of Corporate Banking at PT Bank Negara Indonesia (Persero) Tbk Singapore Branch and Non-Executive Director (Commissioner) at BNI Securities Pte. Ltd highlight his strategic minds. His contribution adds great value to the company.</p>
        </Modal.Body>
      </Modal>
      {/* <Modal id="who" centered show={show7} onHide={handleClose7}>
        <Modal.Body>
          <h3>Senior Vice President of Finance</h3>
          <h2>Darryl Aldjufrie</h2>
          <p>An experienced strategist, entrepreneur, and finance specialist with a passion to develop businesses. He has proven records of managing finances for various types of industries, ranging wide from technology, real estate & property, to consultant services. Up until now, Mr. Darryl has only offered the best performance and delivered outstanding results for his work. With years of excellent portfolio and experience, Mr. Darryl's professionalism and skills are much aligned with Risjadson Bhumi Nusantara's goal.</p>
        </Modal.Body>
      </Modal> */}
    </div>
  )
}

export default Who;