import "../../Style/Nav.scss";
import gsap from "gsap";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import $ from "jquery";
import Dropdown from 'react-bootstrap/Dropdown';
import { HashLink } from 'react-router-hash-link';
import cld from "../../config/cloudcf"
import { AdvancedImage } from "@cloudinary/react";
function Nav () {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const ToogleMenu = () => {
    if (show) {
      $('.modal-dialog').addClass('show-off')
      $('.modal-dialog').removeClass('show-on')
      setTimeout(function () {
        setShow(false);
      }, 790);
    } else {
      setShow(true);
    }
  };

  const ScrollDirector = () => {
    window.location.href = ('/about');
    $('html, body').animate({
      scrollTop: $('#Sec-3').offset().top
    }, 1000);
  }

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -100;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  }

  const Logo = cld.image("rbn/logo/RBN-Nav")

  return (
    <div className="Nav-Container">
      <div className="Nav-Left-Container">
        <a href="/">
          <AdvancedImage className="image" cldImg={Logo} />
        </a>
      </div>
      <div className="Nav-Right-Container">
        <div className="Desktop">
          <a>
            <Dropdown >
              <Dropdown.Toggle variant="success" className="dropdown" id="dropdown-autoclose-true">
                About
              </Dropdown.Toggle>

              <Dropdown.Menu className="dropdown">
                <Dropdown.Item  className="clr-wht-padding-0" >
                  <HashLink className="a" to={"/about#risjadsongroup"} >
                    Risjadson Group
                  </HashLink>
                </Dropdown.Item>
                <Dropdown.Item className="clr-wht-padding-0">
                  <HashLink className="a" to={"/about#risjadsonland"}>
                    Risjadson Land
                  </HashLink>
                </Dropdown.Item>
                <Dropdown.Item className="clr-wht-padding-0">
                  <HashLink className="a"  to={"/about#ourleader"} >
                    Our Leaders'
                    <span>Past Involvement</span>
                  </HashLink>
                </Dropdown.Item>
                <Dropdown.Item  className="clr-wht-padding-0">
                  <HashLink className="a" to={"/about#esg"}>
                    ESG
                  </HashLink>
                </Dropdown.Item>
                <Dropdown.Item  className="clr-wht-padding-0">
                  <HashLink className="a" to={"/about#rbm"}>
                    RiBT
                  </HashLink>
                </Dropdown.Item>
                {/* <Dropdown.Item  className="clr-wht-padding-0">
                  <HashLink className="a" to={"/about#rbp"}>
                    RBP
                  </HashLink>
                </Dropdown.Item> */}
              </Dropdown.Menu>
            </Dropdown>
          </a>
          <a>
            <Dropdown>
              <Dropdown.Toggle variant="success" className="dropdown" id="dropdown-autoclose-true">
                Who
              </Dropdown.Toggle>

              <Dropdown.Menu className="dropdown">
                <Dropdown.Item href="/who" className="clr-wht-padding-0">
                  <HashLink className="a" to={"/who#Director"}>
                  Director
                  </HashLink></Dropdown.Item>
                <Dropdown.Item href="/who" className="clr-wht-padding-0">
                  <HashLink className="a" to={"/who#Comm"}>
                  Commisioners
                  </HashLink></Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </a>
          <a href="/projects">
            <span>Projects</span>
          </a>
          <a href="/contact">
            <span>Contact Us</span>
          </a>
        </div>
        <div className="Mobile">
          <div className="Nav-btn" onClick={ToogleMenu}>
            <span className="Line-Top"></span>
            <span className="Line-Middle"></span>
            <span className="Line-Bottom"></span>
          </div>
        </div>
      </div>
      {/* <-- Modal body--> */}
      <Modal id="Navbar" show={show} animation={false} dialogClassName="show-on">
        <Modal.Body>
          <div className="Buttons" >
            <a href="/">
              <span>
                Home
              </span>
            </a>
            <a href="/about">
              <span>
                About
              </span>
            </a>
            <a href="/who">
              <span>
                Who
              </span>
            </a>
            <a href="/projects">
              <span>
                Projects
              </span>
            </a>
            <a href="/contact">
              <span>
                Contact Us
              </span>
            </a>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Nav;
