import "../../Style/Footer.scss";
import Logo from "../../Assets/Images/Footer/RBN-logo.png";
import { HashLink } from "react-router-hash-link";
function Footer() {
  return (
    <div className="Footer-Container">
      <div className="Footer-Wrapper">
        <div className="Footer-Top-Section">
          <div className="Top-Left-Section">
            <a href="/">
              <img src={Logo} />
            </a>
          </div>
          <div className="Top-Right-Section">
            <div className="About padding-20 d-flex-flex-column">
              <a href="/about">
                <p>ABOUT</p>
              </a>
              <HashLink to={"/about#risjadsongroup"}>
                <span>Risjadson Group</span>
              </HashLink>
              <HashLink to={"/about#risjadsonland"}>
                <span>Risjadson Land</span>
              </HashLink>
              <HashLink to={"/about#ourleader"}>
                <span>Our Leaders' Past</span>
                  <span>Involvement</span>
              </HashLink>
              <HashLink to={"/about#esg"}>
                <span>ESG</span>
              </HashLink>
              <HashLink to={"/about#rbm"}>
                <span>RiBT</span>
              </HashLink>
              {/* <HashLink to={"/about#rbm"}>
                <span>RBP</span>
              </HashLink> */}
            </div>

            <div className="Profile padding-20 d-flex-flex-column">
              <a href="/who" className="fixed">
                <p>WHO</p>
              </a>
              <HashLink to={"/who#Director"}>
                <span>Director</span>
              </HashLink>
              <HashLink to={"/who#Comm"}>
                <span>Commisioners</span>
              </HashLink>
            </div>
            <div className="Partner padding-20 d-flex-flex-column">
              <a href="/projects">
                <p>PROJECTS</p>
              </a>
              <HashLink to={"/projects#Bali"}>
                <span>Philippines</span>
              </HashLink>
              <HashLink to={"projects#Kalimantan"}>
                <span>Kalimantan</span>
              </HashLink>
            </div>
            <div className="Portofolio padding-20 d-flex-flex-column">
              <a href="/contact">
                <p>CONTACT US</p>
              </a>
              <span>E-mail</span>
            </div>
          </div>
        </div>
        <div className="Footer-Bottom-Section">
          <div className="Left-Text-Section">
            <p>
              <span>
                Menara Batavia 30th Floor
                <span>Jl. K.H. Mas Mansyur, Kav. 126 Jakarta 10250 Indonesia</span>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
